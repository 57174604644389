import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { RiLogoutCircleLine, RiBarChartFill } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { BiEnvelope } from "react-icons/bi";
import { BiLogOut } from "react-icons/bi";
import { logout } from "../../utility/auth";
import { IoIosArrowForward, IoIosApps } from "react-icons/io";

import EdsgLogo from "../../assets/images/edsg-logo-250.png";

const Sidebar = ({ history, menuList, loadingMenu }) => {
  const {
    location: { pathname },
  } = history;

  const handleLogout = async () => {
    await logout();
  };

  return (
    <nav className="page-sidebar">
      <div className="sidebar-header">
        <img src={EdsgLogo} alt="logo" className="brand" width="150" />
      </div>

      <div className="sidebar-menu">
        <ul className="menu-items">
          <li>
            <RiBarChartFill className="icon" />
            <a
              href="/portal-dashboard"
              role="presentation"
              className="has-sub-menu pointer"
            >
              <span className="title">Dashboard</span>
            </a>
          </li>

          <li>
            <CgProfile className="icon" />
            <a
              href="/portal-dashboard/profile"
              role="presentation"
              className="has-sub-menu pointer"
            >
              <span className="title">Profile</span>
            </a>
          </li>

          {!loadingMenu && menuList ? (
            menuList.map((item, i) => (
              <MenuItem props={item} pathname={pathname} key={i} />
            ))
          ) : (
            <li>
              <p>Loading menu...</p>
            </li>
          )}

          <li>
            <BiEnvelope className="icon" />
            <a
              href="/portal-dashboard/settings"
              role="presentation"
              className="has-sub-menu pointer"
            >
              <span className="title">Settings</span>
            </a>
          </li>

          <li>
            <BiLogOut className="icon" />
            <a
              href="#"
              onClick={handleLogout}
              role="presentation"
              className="has-sub-menu pointer"
            >
              <span className="title">Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

const MenuItem = ({ props: { category, icon, module }, pathname }) => {
  const [isShowingSub, setIsShowingSub] = useState(false);

  return (
    <>
      <li>
        {/* {icon} */}
        <IoIosApps className="icon" />
        {module ? (
          <a href={"#"}>
            <span
              onClick={() => setIsShowingSub(!isShowingSub)}
              role="presentation"
              className="has-sub-menu pointer"
            >
              <span className="title">{category}</span>
            </span>
            {module && (
              <IoIosArrowForward
                className={`${isShowingSub ? "open" : ""} arrow`}
              />
            )}
          </a>
        ) : (
          <a href={"#"}>
            <span className="title">{category}</span>
          </a>
        )}
      </li>
      {module && isShowingSub && (
        <ul className={`${isShowingSub ? "show" : ""} sub-menu`}>
          {module &&
            module.map((sub, i) => (
              <li
                className={`${pathname === sub.address ? "active" : ""}`}
                key={i}
              >
                <a href={sub.address}>{sub.name}</a>
              </li>
            ))}
        </ul>
      )}
    </>
  );
};

export default Sidebar;
