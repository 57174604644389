/* eslint-disable */

const paths = {
  login: "global/api/employee/login",
  postPassword2: "services/api/resetpassword/resetpassword",
  notification: "services/api/notifications",
  employeeProfile: "global/api/employee/profile",
};

export default paths;
