import React from 'react';

import TextInput from '../../inputs/TextInput';

import { formatDateforPicker } from '../../../utility/dateTime';

const PersonalDetails = ({
  data
}) => (
  <div className="w-100 official-details">
    {!data || data === null ? (
      <p>Personal Detail is empty</p>
    ) : (
      <>
        <div className="row">
          <div className="col-4 no-margin">
            <TextInput
              className="w-100 m-b-10"
              label="Staff ID"
              value={data.staffId}
              editable
            />
          </div>
          <div className="col-4 no-margin">
            <TextInput
              className="w-100 m-b-10"
              label="Title"
              value={data.title || 'empty'}
              editable
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 no-margin">
            <TextInput
              className="w-100 m-b-10"
              label="Surname"
              value={data.lastName || 'empty'}
              editable
            />
          </div>
          <div className="col-4 no-margin">
            <TextInput
              className="w-100 m-b-10"
              label="First Name"
              value={data.firstName || 'empty'}
              editable
            />
          </div>
          <div className="col-4 no-margin">
            <TextInput
              className="w-100 m-b-10"
              label="Middle Name"
              value={data.middleName || 'empty'}
              editable
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 no-margin">
            <TextInput
              type={data.dob !== null ? 'date' : null}
              className="w-100 m-b-10"
              label="Date of Birth"
              maxDate={formatDateforPicker(new Date())}
              value={data.dob || 'empty'}
              editable
            />
          </div>
          <div className="col-4 no-margin">
            <TextInput
              className="w-100 m-b-10"
              label="Gender"
              value={data.gender || 'empty'}
              editable
            />
          </div>
          <div className="col-4 no-margin">
            <TextInput
              className="w-100 m-b-10"
              label="Marital Status"
              value={data.maritalStatus || 'empty'}
              editable
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 no-margin">
            <TextInput
              className="w-100 m-b-10"
              label="Place of Birth"
              value={data.placeOfBirth || 'empty'}
              editable
            />
          </div>
          <div className="col-4 no-margin">
            <TextInput
              className="w-100 m-b-10"
              label="Nationality"
              value={data.nationality.name || 'empty'}
              editable
            />
          </div>
          <div className="col-4 no-margin">
            <TextInput
              className="w-100 m-b-10"
              label="State of Origin"
              value={data.state.name || 'empty'}
              editable
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 no-margin">
            <TextInput
              className="w-100 m-b-10"
              label="Religion"
              value={data.religion.name || 'empty'}
              editable
            />
          </div>
          <div className="col-4 no-margin">
            <TextInput
              className="w-100 m-b-10"
              label="Mother's Maiden Name"
              value={data.motherMaidenName || 'empty'}
              editable
            />
          </div>
          <div className="col-4 no-margin">
            <TextInput
              type={data.weddingAnniversary !== null ? 'date' : null}
              className="w-100 m-b-10"
              label="Wedding Anniversary"
              maxDate={formatDateforPicker(new Date())}
              value={data.weddingAnniversary || 'empty'}
              editable
            />
          </div>
        </div>
      </>
    )}
  </div>
);
export default PersonalDetails;
