/* eslint-disable */
import notification from './notification';
import Cookies from 'js-cookie';


export const logout = () => {

  notification({
    title: 'SESSION TIMEOUT',
    message:'You are being logged out due to session timeout.',
    type: 'danger',
  });

 
  setTimeout(() => {
    window.location.assign(localStorage.getItem('timeOutUrl'));
  }, 2000);

  // localStorage.removeItem('_AppToken');
  // localStorage.removeItem('tokenExist');
  // localStorage.removeItem('token');
  // localStorage.removeItem('requestId');
  
};

export default logout;