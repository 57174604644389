/* eslint-disable */
import * as React from "react";

const DependantDetails = (props) => {
  return (
    <div className="table-view m-t-10">
      <div className="table-header">
        <div className="table-row">
          <div className="th">
            <p>Name</p>
          </div>
          <div className="th">
            <p>Address</p>
          </div>
          <div className="th">
            <p>Date Of Birth</p>
          </div>
          <div className="th">
            <p>Relation</p>
          </div>
          <div className="th">
            <p>Mobile</p>
          </div>
          <div className="th">
            <p>Remarks</p>
          </div>
        </div>
      </div>
      {props.data.length === 0 ? (
        <div className="table-body">
          <p>Dependant Detail is empty</p>
        </div>
      ) : (
        <div className="table-body">
          {props.data.map((value, i) => (
            <div className="table-row" key={value.id}>
              <div className="td" style={{ maxWidth: "350px" }}>
                {value.depName || "empty"}
              </div>
              <div className="td">{value.depAddress || "empty"}</div>
              <div className="td">{value.depDoB || "empty"}</div>
              <div className="td">{value.depRelation || "empty"}</div>
              <div className="td">{value.depMobile || "empty"}</div>
              <div className="td">{value.remarks || "empty"}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DependantDetails;
