/* eslint-disable */
import * as React from "react";

const EducationDetails = (props) => {
  const [dashboardDetail, setdashboardDetail] = React.useState(false);
  return (
    <div className="table-view m-t-10">
        <div className="table-header">
          <div className="table-row">
            <div className="th" ><p>Certificate</p></div>
            <div className="th"><p>Class Of Degree</p></div>
            <div className="th" ><p>Course</p></div>
            <div className="th"><p>Year Of Graduation</p></div>
            <div className="th"><p>School Name</p></div>
            <div className="th"><p>Remarks</p></div>
          </div>
        </div>
        {props.data.length === 0 ? (
          <div className="table-body">
            <p>Education Detail is empty</p>
          </div>
        ) : (

        <div className="table-body">
          {props.data.map((value, i) => (
            <div className="table-row" key={value.id}>
              <div className="td" style={{ maxWidth: '350px' }}>{value.certificate || 'empty'}</div>
              <div className="td">{value.classOfDegree || 'empty'}</div>
              <div className="td">{value.course || 'empty'}</div>
              <div className="td">{value.graduateYear || 'empty'}</div>
              <div className="td">{value.schoolName || 'empty'}</div>
              <div className="td">{value.remarks || 'empty'}</div>
            </div>
          ))}
        </div>
        )}
      </div>
  );
};

export default EducationDetails;
