/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import React, { useEffect, useState } from "react";

import Loader from "react-loader-spinner";
import Moment from "react-moment";
import moment from "moment";
import ProfilePix from "../../assets/images/profile-pix.jpg";
import { get } from "../../services/fetch";
import OfficialDetails from "../layouts/profileDetails/OfficialDetails";
import PersonalDetails from "../layouts/profileDetails/PersonalDetails";
import DependantDetails from "../layouts/profileDetails/DependantDetails";
import EducationDetails from "../layouts/profileDetails/EducationDetails";
import ProfessionalDetails from "../layouts/profileDetails/ProfessionalDetails";
import NextOfKinsDetails from "../layouts/profileDetails/NextOfKinsDetails";
import { formatFileUrl } from "../../utility/general";

import { decodeToken } from "../../utility/auth";

import notification from "../../utility/notification";
// import IsLoading from '../common/IsLoading';

// import LocationDetails from '../layouts/LocationDetails';

const Profile = ({ history }) => {
  const [currentForm, setCurrentForm] = useState("official-details");
  const [isUpdating, setIsUpdating] = useState(false);

  const [userProfile, setUserProfile] = useState({});
  const [userDependants, setUserDependants] = useState({});
  const [userEducation, setUserEducation] = useState({});
  const [userProfessional, setUserProfessional] = useState({});
  const [userNextOfKins, setUserNextOfKins] = useState({});
  const [wrongStaffId, setWrongStaffId] = useState(false);

  useEffect(async () => {
    setIsUpdating(true);
    window.scrollTo(0, 0);
    document.title = "EDSG Profile";

    const staffId = localStorage.getItem("staffId");

    if (staffId === undefined || !staffId) {
      // return notification, set loader false
      setWrongStaffId(true);
      setIsUpdating(false);
      notification({
        title: "Invalid National Identity Number",
        message:
          "Update your Staff ID with your valid National Identity Number to view your profile",
        type: "danger",
        duration: 20000,
      });
      return;
    }

    const res = await get({
      endpoint: `global/api/employee/profile/${staffId}`,
      auth: true,
    });
    console.log(res);

    const dependants = await get({
      endpoint: `global/api/dependants/${staffId}`,
      auth: true,
    });

    const education = await get({
      endpoint: `global/api/education/${staffId}`,
      auth: true,
    });

    const professional = await get({
      endpoint: `global/api/profcerts/${staffId}`,
      auth: true,
    });

    const nextOfKin = await get({
      endpoint: `global/api/nok/${staffId}`,
      auth: true,
    });

    setUserProfile(res && res.data.data);
    setUserDependants(dependants && dependants.data);
    setUserEducation(education && education.data);
    setUserProfessional(professional && professional.data);
    setUserNextOfKins(nextOfKin && nextOfKin.data);
    setIsUpdating(false);
    setWrongStaffId(false);
  }, []);

  const addDefaultSrc = (ev) => {
    ev.target.src = ProfilePix;
  };

  return (
    <div className="w-100 p-r">
      <div className="w-100 flex space-between flex-v-center">
        <h2>Profile</h2>
      </div>
      {isUpdating ? (
        <div>
          <Loader type="ThreeDots" color="green" height={100} width={100} />
        </div>
      ) : (
        <>
          <div className="w-100 m-t-20 user-full-details">
            <div className="tab-btns w-100">
              <button
                type="button"
                onClick={() => setCurrentForm("official-details")}
                className={currentForm === "official-details" ? "active" : ""}
              >
                Official Details
              </button>
              <button
                type="button"
                onClick={() => setCurrentForm("personal")}
                className={currentForm === "personal" ? "active" : ""}
              >
                Personal
              </button>
              <button
                type="button"
                onClick={() => setCurrentForm("professional")}
                className={currentForm === "professional" ? "active" : ""}
              >
                Professional
              </button>
              <button
                type="button"
                onClick={() => setCurrentForm("education")}
                className={currentForm === "education" ? "active" : ""}
              >
                Education
              </button>
              <button
                type="button"
                onClick={() => setCurrentForm("dependant")}
                className={currentForm === "dependant" ? "active" : ""}
              >
                Dependants
              </button>
              <button
                type="button"
                onClick={() => setCurrentForm("nextofkin")}
                className={currentForm === "nextofkin" ? "active" : ""}
              >
                Next Of Kin
              </button>
            </div>
            {wrongStaffId === true ? (
              <>
                <br />
                <p>
                  <i>
                    Update your staffId with your valid National Identity Number
                    to view your profile
                  </i>
                </p>
              </>
            ) : (
              <div className="w-100 flex">
                <div className="left">
                  <div className="content w-100">
                    {currentForm === "official-details" && (
                      <OfficialDetails data={userProfile} />
                    )}
                    {currentForm === "personal" && (
                      <PersonalDetails data={userProfile} />
                    )}
                    {currentForm === "education" && (
                      <EducationDetails data={userEducation} />
                    )}
                    {currentForm === "professional" && (
                      <ProfessionalDetails data={userProfessional} />
                    )}
                    {currentForm === "dependant" && (
                      <DependantDetails data={userDependants} />
                    )}
                    {currentForm === "nextofkin" && (
                      <NextOfKinsDetails data={userNextOfKins} />
                    )}
                  </div>
                </div>
                <div className="right">
                  <div className="w-100 m-t-40 center-text profile-brief">
                    <img
                      onError={addDefaultSrc}
                      src={
                        (userProfile && userProfile.profilePicture) ||
                        ProfilePix
                      }
                      alt={userProfile && userProfile.firstName}
                    />
                    <div className="brief m-t-20">
                      <h4>
                        {`${(userProfile && userProfile.firstName) || ""} ${
                          (userProfile && userProfile.lastName) || ""
                        }`}
                      </h4>
                      <hr />
                      <p className="m-b-10">
                        <b>Engagement Date: </b>
                        <Moment
                          date={userProfile && userProfile.dateOfFirstApp}
                          format="YYYY-MM-DD"
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Profile;
