/* eslint-disable */
import * as React from "react";

const ProfessionalDetails = (props) => {
  return (
    <div className="table-view m-t-10">
      <div className="table-header">
        <div className="table-row">
          <div className="th">
            <p>Certificate Type</p>
          </div>
          <div className="th">
            <p>Certificate Obtained</p>
          </div>
          <div className="th">
            <p>Certificate Number</p>
          </div>
          <div className="th">
            <p>Certificate Expiration Date</p>
          </div>
          <div className="th">
            <p>Certificate Year</p>
          </div>
          <div className="th">
            <p>Remarks</p>
          </div>
        </div>
      </div>
      {props.data.length === 0 ? (
        <div className="table-body">
          <p>Professional is empty</p>
        </div>
      ) : (
        <div className="table-body">
          {props.data.map((value, i) => (
            <div className="table-row" key={value.id}>
              <div className="td" style={{ maxWidth: "350px" }}>
                {value.certType || "empty"}
              </div>
              <div className="td">{value.certObtained || "empty"}</div>
              <div className="td">{value.certNumber || "empty"}</div>
              <div className="td">{value.certExpirationDate || "empty"}</div>
              <div className="td">{value.certYear || "empty"}</div>
              <div className="td">{value.remarks || "empty"}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProfessionalDetails;
