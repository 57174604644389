/* eslint no-use-before-define: 0 */
import React, { useEffect, useState } from "react";
import Table from "antd/es/table";
import Menu from "antd/es/menu";
import Dropdown from "antd/es/dropdown";
import Button from "antd/es/button";
import Space from "antd/es/space";
import Radio from "antd/es/radio";
import DatePicker from "antd/es/date-picker";
import Modal from "antd/es/modal";
import moment from "moment";
import Loader from "react-loader-spinner";
import Cookies from "js-cookie";
import Page6Cards from "../layouts/Page6Cards";
import SearchInput from "../inputs/SearchInput";
import Greeting from "../layouts/Greeting";
import ServiceWidget from "../layouts/ServiceWidget";
import {
  DashboardMainStatistics,
  DashboardMdaRequestCount,
  RequestLogs,
  FilterRequestLogs,
  UpdateRequestItem,
  GetModuleLink,
} from "../../utility/fetch";
import Notification from "../../utility/notification";
import { moduleNameData } from "../columns/requestLogColumn";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Pagination, Select } from "antd";
import parse from "html-react-parser";
import axios from "axios";

const { RangePicker } = DatePicker;
const { Option } = Select;

const dateFormat = "YYYY-MM-DD";

const approvalEnabledMdas = [
  "leave plan",
  "leave request",
  "training management",
  "general request",
  "grievance",
  "memo manager",
  "expense",
  "asset management",
];

const BASE_API_URL = `https://edogoverp.com/services/api`;

const Dashboard = ({ history }) => {
  const [title, setTitle] = useState("");
  const [placement, SetPlacement] = React.useState("Days");
  const [receivedMemos, setReceivedMemos] = useState(0);
  const [treateddMemos, setTreatedMemos] = useState(0);
  const [pendingMemos, setPendingMemos] = useState(0);
  const [approvedMemos, setApprovedMemos] = useState(0);
  const [rejectedMemos, setRejectedMemos] = useState(0);
  const [searchText, setSearchText] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [mdaRequests, setMdaRequests] = useState(0);
  const [requestLogs, setRequestLogs] = useState();
  const [loading, setLoading] = useState(false);
  const [singleRequest, setSingleRequest] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [details, setDetails] = useState("");
  const [activeMemo, setActiveMemo] = useState("pending");
  const [totalElement, setTotalElement] = useState();
  const [limit, setLimit] = useState(20);
  const [current, setCurrent] = useState(1);
  const [status, setStatus] = useState("pending");
  const [mdas, setMdas] = useState([]);
  const [selectedMdas, setSelectedMdas] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const placementChange = (e) => {
    SetPlacement(e.target.value);
  };

  const paginationChangeHandler = (current, pageSize) => {
    setLimit(pageSize);
    setCurrent(current);
  };

  const getMdas = () => {
    axios.get(`${BASE_API_URL}/ServiceManagement/get_mdas`).then((response) => {
      const roleList = response?.data.map((item) => ({
        name: `${item.name}`,
        value: item.id,
      }));
      setMdas(roleList);
    });
  };

  useEffect(() => {
    fetchRequestLogs();
  }, [current, limit, status, searchText]);

  const filterLogs = (activeMemo) => {
    setActiveMemo(activeMemo);
    setLimit(20);
    setCurrent(1);
    setStatus(activeMemo);
    // if (activeMemo.toLowerCase() != "received") {
    //   const result = allLogs.filter(
    //     (m) => m.status.toLowerCase() == activeMemo.toLowerCase()
    //   );
    //   setRequestLogs(result);
    // } else {
    //   setRequestLogs(allLogs);
    // }
  };

  const onChangeDate = (value) => {
    let from = value[0].format(dateFormat);
    let to = value[1].format(dateFormat);
    setFromDate(from);
    setToDate(to);
    filterDate(from, to);
  };
  const filterDate = async (start, end) => {
    try {
      const payload = {
        pageNumber: current,
        status,
        queryFilter: searchText,
        pageSize: limit,
        mdas: selectedMdas,
      };
      const result = await FilterRequestLogs(payload, start, end);

      if (result?.data?.statusCode === "00") {
        setRequestLogs(
          result?.data?.result?.map((request) => ({
            ...request,
            lastUpdated: moment(request?.lastUpdated)?.format("LLLL"),
          }))
        );
        let tot = result?.headers["x-pagination"];
        let res = JSON.parse(tot);
        setTotalElement(res?.TotalCount);
        setCurrent(res?.CurrentPage);
      }
    } catch (ex) {
      console.log(ex.Response);
    }
  };

  const filterMdas = async () => {
    try {
      const payload = {
        pageNumber: current,
        status,
        queryFilter: searchText,
        pageSize: limit,
        mdas: selectedMdas,
      };
      const result = await FilterRequestLogs(payload, fromDate, toDate);

      if (result?.data?.statusCode === "00") {
        setRequestLogs(
          result?.data?.result?.map((request) => ({
            ...request,
            lastUpdated: moment(request?.lastUpdated)?.format("LLLL"),
          }))
        );
        let tot = result?.headers["x-pagination"];
        let res = JSON.parse(tot);
        setTotalElement(res?.TotalCount);
        setCurrent(res?.CurrentPage);
      }
    } catch (ex) {
      console.log(ex.Response);
    }
  };

  const fetchRequestLogs = async () => {
    // setLoading(true);
    try {
      const payload = {
        pageNumber: current,
        status,
        queryFilter: searchText,
        pageSize: limit,
      };
      const result = await RequestLogs(payload);

      if (result?.data?.statusCode === "00") {
        setRequestLogs(
          result?.data?.result?.map((request) => ({
            ...request,
            lastUpdated: moment(request?.lastUpdated)?.format("LLLL"),
          }))
        );
        let tot = result?.headers["x-pagination"];
        let res = JSON.parse(tot);
        setTotalElement(res?.TotalCount);
        setCurrent(res?.CurrentPage);
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    // setLoading(false);
  };

  const fetchDashboardMdaRequestCount = async () => {
    setLoading(true);
    try {
      const result = await DashboardMdaRequestCount();
      if (result?.data?.statusCode === "00") {
        setMdaRequests(result?.data?.result);
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    setLoading(false);
  };

  const fetchDashboardMainStatistics = async () => {
    setLoading(true);
    try {
      const result = await DashboardMainStatistics();
      if (result?.status === 200) {
        const memo = result?.data;
        setReceivedMemos(memo?.receivedMemos);
        setTreatedMemos(
          Number(memo?.approvedMemos) + Number(memo?.rejectedMemos)
        );
        setPendingMemos(memo?.pendingMemos);
        setApprovedMemos(memo?.approvedMemos);
        setRejectedMemos(memo?.rejectedMemos);
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    setLoading(false);
  };

  const updateRequestItemStatus = async (payload) => {
    setLoading(true);
    try {
      const result = await UpdateRequestItem([payload]);
      if (result?.data?.statusCode == "00") {
        Notification({
          title: `${payload?.status} Successful!`,
          message: `${result?.data?.apiMessage}`,
          type: "success",
        });
        fetchRequestLogs();
        fetchDashboardMainStatistics();
        fetchDashboardMdaRequestCount();
      } else {
        Notification({
          title: "Error!",
          message: `${result?.data?.apiMessage}`,
          type: "danger",
        });
      }
    } catch (ex) {
      Notification({
        title: "Error!",
        message: `${ex.response?.data?.apiMessage}`,
        type: "danger",
      });
    }
    setLoading(false);
  };

  const RedirectToModuleLink = async (payload) => {
    setLoading(true);
    try {
      const result = await GetModuleLink([payload]);
      if (result?.data?.statusCode == "00") {
        window.location.href = `${
          result?.data?.url
        }${result?.data?.address.replace("https://edogoverp.com?", "?")}`;
      } else {
        Notification({
          title: "Error!",
          message: `${result?.data?.apiMessage}`,
          type: "danger",
        });
      }
    } catch (ex) {
      Notification({
        title: "Error!",
        message: `${ex.response?.data?.apiMessage}`,
        type: "danger",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getMdas();
    fetchDashboardMainStatistics();
    fetchDashboardMdaRequestCount();
    setActiveMemo("pending");
  }, []);

  const menu = (record) => (
    <Menu>
      {/* {record.status?.toLowerCase() == "pending" &&
        approvalEnabledMdas.includes(record?.moduleName?.toLowerCase()) && (
          <>
            <Menu.Item
              onClick={() => {
                record.status = "7";
                updateRequestItemStatus(record);
              }}
            >
              <a rel="noopener noreferrer" href="#">
                Approve
              </a>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                record.status = "8";
                updateRequestItemStatus(record);
              }}
            >
              <a rel="noopener noreferrer" href="#">
                Decline
              </a>
            </Menu.Item>
          </>
        )} */}

      <Menu.Item
        onClick={() => {
          record.status = "0";
          RedirectToModuleLink(record);
        }}
      >
        <a rel="noopener noreferrer" href="#">
          View
        </a>
      </Menu.Item>
    </Menu>
  );

  const mdaRequestsColumns = [
    {
      title: (
        <div>
          {" "}
          <strong className="family m-r-10">MDAs Request</strong>
          {/* <button className="rounded family">
            <strong>Show All</strong>
          </button> */}
        </div>
      ),
      dataIndex: "mdaName",
      //   width: '55%',
      editable: false,
    },
    {
      title: <strong className="family">Received</strong>,
      dataIndex: "received",
      width: "10%",
      editable: false,
    },
    {
      title: <strong className="family">Treated</strong>,
      dataIndex: "treated",
      width: "10%",
      editable: false,
    },
    {
      title: <strong className="family">Pending</strong>,
      dataIndex: "pending",
      width: "10%",
    },
    {
      title: <strong className="family">Approved</strong>,
      dataIndex: "approved",
      width: "10%",
    },
  ];

  const displayTruncatedDetails = (details, number) => {
    if (details.length > number) {
      return `${details.substring(0, 40)}...`;
    } else {
      return details;
    }
  };

  const requestLogsColums = [
    {
      title: <strong className="family">Request Title</strong>,
      dataIndex: "requestType",
      width: "15%",
      editable: false,
    },
    {
      title: <strong className="family">Request Initiator</strong>,
      dataIndex: "requestInitiator",
      width: "10%",
      editable: false,
    },
    {
      title: <strong className="family">Module</strong>,
      dataIndex: "moduleName",
      width: "10%",
      editable: false,
    },
    {
      title: <strong className="family">Request Agency/MDA</strong>,
      dataIndex: "mdaName",
      width: "10%",
      editable: false,
    },
    {
      title: <strong className="family">Request Details</strong>,
      // dataIndex: "requestDetails",
      render: (item) => (
        <p
          style={{ cursor: "pointer" }}
          // onClick={() => {
          //   setDetails(item?.requestDetails);
          //   setModalVisible(true);
          // }}
        >
          {item?.requestDetails
            ? parse(displayTruncatedDetails(item?.requestDetails, 20))
            : ""}
        </p>
      ),
      width: "20%",
      align: "center",
    },
    {
      title: <strong className="family">Last Updated</strong>,
      dataIndex: "lastUpdated",
      width: "5%",
    },
    {
      title: <strong className="family">Status</strong>,
      dataIndex: "status",
      width: "5%",
    },
    {
      title: <strong className="family">Action</strong>,
      dataIndex: "action",
      key: "action",
      width: "5%",
      render: (text, record) => (
        <Space direction="vertical">
          <Space wrap>
            <Dropdown
              onClick={(event) => event.stopPropagation()}
              overlay={menu(record)}
              trigger={["click"]}
              placement="bottom"
            >
              <Button style={{ backgroundColor: "#1C811C", color: "white" }}>
                Select
              </Button>
            </Dropdown>
          </Space>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - Dashboard";
    setTitle("Dashboard");
  }, []);

  const handleChange = (value) => {
    setSelectedMdas(value);
  };

  return (
    <div className="w-100">
      {loading ? (
        <div>
          <Loader type="ThreeDots" color="green" height={100} width={100} />
        </div>
      ) : (
        <>
          <Greeting />
          <div className="flex space-between w-100 m-b-50">
            <Page6Cards
              cardTitle="Recieved Requests"
              count={receivedMemos}
              onClick={() => filterLogs()}
              isActive={!activeMemo}
            />
            {/* <Page6Cards
              cardTitle="Treated Requests"
              count={treateddMemos}
              onClick={() => fetchRequestLogs("Treated")}
              isActive={activeMemo == "Treated"}
            /> */}
            <Page6Cards
              cardTitle="Pending Requests"
              count={pendingMemos}
              onClick={() => filterLogs("pending")}
              isActive={activeMemo == "pending"}
            />
            <Page6Cards
              cardTitle="Approved Requests"
              count={approvedMemos}
              onClick={() => filterLogs("approved")}
              isActive={activeMemo == "approved"}
            />
            <Page6Cards
              cardTitle="Declined Requests"
              count={rejectedMemos}
              onClick={() => filterLogs("declined")}
              isActive={activeMemo == "declined"}
            />
          </div>

          <div className="flex space-between m-b-20">
            <RangePicker onChange={onChangeDate} />
            <div className="flex">
              <div className={`search-input`}>
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select"
                  onChange={handleChange}
                >
                  {mdas.map((item) => (
                    <Option
                      key={item.value}
                      className="customized_input"
                      value={item.value}
                    >
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <button style={{ cursor: "pointer" }} onClick={filterMdas}>
                Search
              </button>
            </div>

            {/* <SearchInput
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            /> */}
          </div>
          <div className="flex space-between w-100 primary-color p-15 ">
            <strong className="family">Request Log</strong>
          </div>
          <div className="w-100">
            <Table
              bordered
              pagination={false}
              dataSource={requestLogs}
              columns={requestLogsColums}
              rowClassName="editable-row"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    RedirectToModuleLink(record);
                  },
                };
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              {totalElement > limit && (
                <Pagination
                  showSizeChanger
                  pageSize={limit}
                  onShowSizeChange={paginationChangeHandler}
                  current={current}
                  total={totalElement}
                  // showTotal={true}
                  onChange={paginationChangeHandler}
                  // pageSizeOptions={pageSizeOptions}
                  responsive
                />
              )}
            </div>
          </div>

          <div style={{ width: "100%" }}>
            <div className="flex space-between m-b-20 align-end "></div>
            <Table
              bordered
              dataSource={mdaRequests}
              columns={mdaRequestsColumns}
              rowClassName="editable-row"
            />
          </div>

          <ServiceWidget />
        </>
      )}

      <Modal
        title="Request Details"
        centered
        visible={modalVisible}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
      >
        <p>{parse(details)}</p>
      </Modal>
    </div>
  );
};

export default Dashboard;
