import React from 'react';

import TextInput from '../../inputs/TextInput';

const OfficialDetails = ({ data }) => (
  <div className="w-100 official-details">
    {!data || data === null ? (
      <p>Official Detail is empty</p>
    ) : (
      <>
        <div className="row">
          <div className="col-6 no-margin">
            <TextInput
              editable
              className="w-100 m-b-10"
              label="Staff ID"
              value={data.staffId}
            />
          </div>
          <div className="col-6 no-margin">
            <TextInput
              className="w-100 m-b-10"
              label="Email Address"
              value={data.email}
              editable
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6 no-margin">
            <TextInput
              className="w-100 m-b-10"
              label="Assigned Role"
              value={data.role && data.role.length !== 0 ? data.role[0].name : 'empty'}
              editable
            />
          </div>
          <div className="col-6 no-margin">
            <TextInput
              className="w-100 m-b-10"
              label="Current Level"
              value={data.currentLevel || 'empty'}
              editable
            />
          </div>
        </div>

        <div className="row">
          <div className="col-6 no-margin">
            <TextInput
              className="w-100 m-b-10"
              label="Salary Account Number"
              value={data.salaryAccountNumber || 'empty'}
              editable
            />
          </div>
          <div className="col-6 no-margin">
            <TextInput
              editable
              className="w-100 m-b-10"
              label="NIN"
              value={data.nin || 'empty'}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6 no-margin">
            <TextInput
              editable
              className="w-100 m-b-10"
              label="Salary Domiciled Bank"
              value={data.salaryDomiciledBank || 'empty'}
            />
          </div>
          <div className="col-6 no-margin">
            <TextInput
              editable
              className="w-100 m-b-10"
              label="BVN"
              value={data.bvn || 'empty'}
            />
          </div>
        </div>
        <TextInput
          className="w-100 m-b-10"
          label="Assigned MDA"
          value={data.mda && Object.keys(data.mda).length !== 0 ? data.mda.name : 'empty'}
          editable
        />
      </>
    )}
  </div>
);

export default OfficialDetails;
