import React, { useState, useEffect } from "react";

import PortalRoutes from "../routes/PortalRoutes";
import Sidebar from "./layouts/Sidebar";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";

import menuList from "../config/sidebarMenu";

import { decodeToken, jwtDecode } from "../utility/auth";
import { get } from "../services/fetch";

function UserPortal(props) {
  const { history } = props;
  const [menuList1, setMenuList] = useState([]);
  const [loadingMenu, setLoadingMenu] = useState(false);

  const mdaId = localStorage.getItem("mdaId");
  const UserID = localStorage.getItem("userId");

  /**
   * @ useEffect function
   * On Portal load,
   * Getting userId from token
   * Getting sidebar modules for that user
   */
  useEffect(async () => {
    setLoadingMenu(true);

    const {
      data: { data },
    } = await get({
      endpoint: `rolemodule/api/ModulesDetails/GetModule?mda=${mdaId}&userid=${UserID}`,
      auth: false,
    });

    setMenuList(data);
    setLoadingMenu(false);
  }, []);

  return (
    <div className="container">
      <Sidebar
        history={history}
        loadingMenu={loadingMenu}
        menuList={menuList1}
      />
      <div className="portal">
        <Header navList={["Home", "Notification", "Set Reminder"]} />
        <div className="content main-content">
          <PortalRoutes />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default UserPortal;
