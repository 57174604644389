import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { get } from "../../services/fetch";
import Loader from "../../assets/svg/loading.svg";
import notification from "../../utility/notification";
import AutoComplete from "../inputs/AutoComplete";
import SelectInput from "../inputs/SelectInput";
import TextareaInput from "../inputs/TextareaInput";
import TextInput from "../inputs/TextInput";

const BASE_API_URL = "https://edogoverp.com/";

const ServiceManagement = (prop) => {
  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(2);
  const [name, setName] = useState(
    `${localStorage.getItem("firstName")} ${localStorage.getItem("lastName")}`
  );
  const [mda, setMda] = useState("");
  const [mdaName, setMdaName] = useState("");
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  // localStorage.getItem('lastName');
  // localStorage.getItem('firstName');

  const [phoneNumber, setPhonenumber] = useState("");
  const [description, setDescription] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [mdas, setMdas] = useState([]);
  const [modules, setModules] = useState([]);
  const [module, setModule] = useState([]);
  const [moduleName, setModuleName] = useState([]);

  //   const [methods, setMethods] = useState([]);

  //   useEffect(() => {
  //     window.scrollTo(0, 0);

  //     if (details && details.id) {
  //       setFormType('edit');
  //       setCategory(details.learningCategoryId || '');
  //       setMethod(details.learningMethodId || '');
  //       setTitle(details.title || '');
  //       setDescription(details.details || '');
  //       setImpactOnindividual(details.impactOnindividual || '');
  //       setImpactOnOrganisation(details.impactOnOrganisation || '');
  //       setStartDate((details.startDate && formatDateforPicker(details.startDate)) || '');
  //       setEndDate((details.endDate && formatDateforPicker(details.endDate)) || '');
  //       setOrganisingBody(details.organisingBody || '');
  //       setLocation(details.location || '');
  //       setTotalCost(details.totalCost || '');
  //       setPersonalExpense(details.personalExpense || '');
  //       setUploadedDocuments(details.documentPath || '');
  //       setDtaExpense(details.dtaExpense)
  // setTransportationExpense(details.transportationExpense)
  // setLocalTransportationExpense(details.localTransportationExpense)
  // setJournalPublicationExpense(details.journalPublicationExpense)
  // setSubscriptionExpense(details.subscriptionExpense)
  // setContingenciesExpense(details.contingenciesExpense)
  //     } else {
  //       setFormType('create');
  //     }

  // getCategories();
  //     getMethods();
  //   }, []);

  const getCategories = async () => {
    const res = await get({
      endpoint: "services/api/ServiceManagement/issue_categories",
    });
    console.log(res);
    console.log("hi");
    if (res && res.status === 200) {
      const { data } = res;
      console.log(data);

      let roleList = data?.map((item) => ({
        name: item.category,
        value: item.id,
      }));
      roleList.unshift({ value: "select item...", name: "select item..." });
      console.log(roleList);
      setCategories(roleList);
      // } else {
      //   notification({
      //   //   title: 'Error Getting Categories',
      //     message: data.message,
      //     type: 'danger'
      //   });
      // }
    } else {
      notification({
        title: "Network",
        message:
          "Something has gone wrong while trying to get list of categories. Please, try again.",
        type: "danger",
      });
    }
  };

  const getCategories2 = () => {
    axios
      .get(`${BASE_API_URL}services/api/ServiceManagement/issue_categories`)
      .then((res) => {
        const { data } = res;
        console.log(data);

        let roleList = data?.map((item) => ({
          name: item.category,
          value: item.id,
        }));
        roleList.unshift({ value: "select item...", name: "select item..." });
        console.log(roleList);
        setCategories(roleList);

        // setMdas(roleList);
      })
      .catch((err) => {});

    // } else {
    //   notification({
    //   //   title: 'Error Getting Categories',
    //     message: data.message,
    //     type: 'danger'
    //   });
    // }
  };

  const getSubCategories2 = () => {
    axios
      .get(
        `${BASE_API_URL}services/api/ServiceManagement/issue_sub_categories?categoryid=${category}`
      )
      .then((res) => {
        const { data } = res;
        console.log(data);

        let roleList = data?.map((item) => ({
          name: item.name,
          value: item.id,
        }));
        roleList.unshift({ value: "select item...", name: "select item..." });
        console.log(roleList);
        // setCategories(roleList);
        setSubCategories(roleList);

        // setMdas(roleList);
      })
      .catch((err) => {});

    // } else {
    //   notification({
    //   //   title: 'Error Getting Categories',
    //     message: data.message,
    //     type: 'danger'
    //   });
    // }
  };

  const getMdas2 = () => {
    axios
      .get(`${BASE_API_URL}services/api/ServiceManagement/get_mdas`)
      .then((res) => {
        const { data } = res;
        console.log(data);
        setMdas(data);

        //   let roleList = data?.map((item) => ({ name: item.name, value: item.id }));
        //  roleList.unshift({value:'select item...', name:'select item...'})
        // console.log(roleList);
        // setMdas(roleList);
      })
      .catch((err) => {});
  };

  const getModules = () => {
    axios
      .get(`${BASE_API_URL}services/api/ServiceManagement/get_modules`)
      .then((res) => {
        const { data } = res;
        console.log(data);
        setModules(data);

        //   let roleList = data?.map((item) => ({ name: item.name, value: item.id }));
        //  roleList.unshift({value:'select item...', name:'select item...'})
        // console.log(roleList);
        // setModules(roleList);
      })
      .catch((err) => {});
  };

  const getMdas = async () => {
    const res = await get({
      endpoint: "services/api/ServiceManagement/get_mdas",
    });
    console.log(res);
    console.log("hi");
    if (res && res.status === 200) {
      const { data } = res;
      console.log(data);

      let roleList = data?.map((item) => ({ name: item.name, value: item.id }));
      roleList.unshift({ value: "select item...", name: "select item..." });
      console.log(roleList);
      setMdas(roleList);
      // } else {
      //   notification({
      //   //   title: 'Error Getting Categories',
      //     message: data.message,
      //     type: 'danger'
      //   });
      // }
    } else {
      notification({
        title: "Network",
        message:
          "Something has gone wrong while trying to get list of categories. Please, try again.",
        type: "danger",
      });
    }
  };

  const getSubCategories = async () => {
    const res = await get({
      endpoint: `services/api/ServiceManagement/issue_sub_categories?categoryid=${category}`,
    });
    console.log(res);
    console.log("hi");
    if (res && res.status === 200) {
      const { data } = res;
      // if (data.code === 1) {
      const roleList = data?.map((item) => ({
        name: item.category,
        value: item.id,
      }));
      console.log(roleList);
      roleList.unshift({ value: "select item...", name: "select item..." });

      setSubCategories(roleList);
      //   } else {
      //     notification({
      //     //   title: 'Error Getting Categories',
      //       message: data.message,
      //       type: 'danger'
      //     });
      //   }
    } else {
      // notification({
      //   title: 'Network',
      //   message: 'Something has gone wrong while trying to get list of categories. Please, try again.',
      //   type: 'danger'
      // });
    }
  };

  useEffect(() => {
    // alert( localStorage.getItem('lastName'))
    getCategories2();
    getMdas2();
    getModules();
  }, []);

  useEffect(() => {
    getSubCategories2();
  }, [category]);

  //   const getMethods = async () => {
  //     const res = await get({ endpoint: '/Request/methods' });
  //     console.log(res);

  //     if (res && res.status === 200) {
  //       const { data } = res;
  //       if (data.code === 1) {
  //         const roleList = data.data
  //           .map((item) => ({ name: item.name, value: item.id }));
  //         console.log(roleList);
  //         setMethods(roleList);
  //       } else {
  //         notification({
  //           title: 'Error Getting Methods',
  //           message: data.message,
  //           type: 'danger'
  //         });
  //       }
  //     } else {
  //       notification({
  //         title: 'Network',
  //         message: 'Something has gone wrong while trying to get list of methods. Please, try again.',
  //         type: 'danger'
  //       });
  //     }
  //   };

  const validateForm = () => {
    if (!category) return "Please, select a category for the request";
    if (!mda) return "Please, select an mda for the request";
    if (!subCategory) return "Please, select a sub category for the request";
    if (!description) return "Please, enter a description for the request";
    if (!phoneNumber) return "Please, enter your phone number";
    if (!email) return "Please, enter your E-mail";
    if (!name) return "Please, enter your name";
    if (!module) return "Please, select the module with an issue";

    //     if (getDaysFromDate(startDate) < 0) return 'Start date must not be behind today';
    //     if (!endDate) return 'Please, enter a end date for the request';
    //     // if (getDaysFromDate(endDate) < 0) return 'end date must not be beyond today';
    //     if (!organisingBody) return 'Please, enter an organising body for the request';
    //     if (!location) return 'Please, enter a location for the training';
    //     // if (!totalCost) return 'Please, enter the training cost';
    //     // if (!personalExpense) return 'Please, enter personal expense for the request';
    return null;
  };

  //   const formHasValue = () => {
  //     if (category) return true;
  //     if (method) return true;
  //     if (title) return true;
  //     if (description) return true;
  //     if (impactOnindividual) return true;
  //     if (impactOnOrganisation) return true;
  //     if (startDate) return true;
  //     if (endDate) return true;
  //     if (organisingBody) return true;
  //     if (location) return true;
  //     if (totalCost) return true;
  //     if (personalExpense) return true;
  //     if (fileArray.length) return true;
  //     return false;
  //   };

  const submitForm = async (type) => {
    const error = validateForm();
    // const error = '';

    if (error) {
      notification({
        title: "Invalid Entry",
        message: error,
        type: "danger",
      });
      return;
    }

    const payload = {
      //       id: 0,
      categoryId: +category,
      subCategoryId: +subCategory,
      otherDetails: "string",
      description: description,
      staffName: name,
      attachments: [
        {
          fileName: "string",
          filePath: "string",
        },
      ],
      email: email,
      phone: phoneNumber,
      moduleName: moduleName,
      mdaName: mdaName,

      isPickedUp: false,
      isClosed: false,
      createdAt: "2021-11-16T10:28:20.050Z",
      modifiedAt: "2021-11-16T10:28:20.050Z",
      isResolved: false,
      modifiedBy: 0,
    };

    setIsSubmitting(true);

    axios
      .post(
        `${BASE_API_URL}services/api/ServiceManagement/create_issue_log`,
        payload
      )
      .then((res) => {
        const { data } = res;
        console.log(data);
        setIsSubmitting(false);
      })
      .catch((err) => {
        setIsSubmitting(false);
      });

    //   const res = await post({ endpoint: 'services/api/ServiceManagement/create_issue_log', body: payload })

    // console.log(res);

    // if (res && res.status === 200) {
    //   const { data } = res;
    //   if (data.code === 1) {
    //     notification({
    //       title: `Successful Request Creation`,
    //       message: data.message,
    //       type: 'success'
    //     });
    //     resetForm();
    //     // window.location.reload();
    //   } else {
    //     notification({
    //       title: `Request Creation Error`,
    //       message: data.message,
    //       type: 'danger'
    //     });
    //   }
    // } else {
    //   notification({
    //     title: 'Network Error',
    //     message: `Something went wrong while creating Request.  a, try again later.`,
    //     type: 'danger'
    //   });
    // }
    // : await put({ endpoint: '/Request/submitrequest', body: payload });
  };

  //   const handleFilesUpload = async () => {
  //     const uploadField = document.getElementById('image-upload');

  //     uploadField.click();
  //     uploadField.onchange = async () => {
  //       if (!uploadField.files[0]) return;
  //       const { type, size } = uploadField.files[0];
  //       console.log(type, size, uploadField.files[0]);

  //       const supportedTypes = [
  //         'jpeg', 'png', 'gif', 'pdf',
  //         'vnd.openxmlformats-officedocument.wordprocessingml.document', 'doc',
  //         'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  //       ];
  //       const fileType = type.slice(type.indexOf('/') + 1);

  //       console.log(uploadedDocuments, b64FileArray.length);
  //       if (uploadedDocuments || b64FileArray.length > 0) {
  //         notification({
  //           title: 'File Limit Exceeded',
  //           message: 'You can only upload one document for your training request.',
  //           type: 'danger'
  //         });
  //         return;
  //       }
  //       if (!supportedTypes.includes(fileType)) {
  //         notification({
  //           title: 'Invalid File Type',
  //           message: 'Invalid file format. Supported file types are pdf, docx, doc, xlsx, jpeg, png and gif',
  //           type: 'danger'
  //         });
  //         return;
  //       }
  //       if (size / 1024 > 1000) {
  //         notification({
  //           title: 'File Too Large',
  //           message: 'The file size must not be more than 1MB',
  //           type: 'danger'
  //         });
  //         return;
  //       }
  //       const fileBase64 = await convertImgToBase64(uploadField.files[0]);
  //       const b64FileSet = new Set(b64FileArray);
  //       const setSize = b64FileSet.size;
  //       const newFileList = [...fileArray];

  //       b64FileSet.add(fileBase64);

  //       if (setSize < b64FileSet.size) {
  //         newFileList.push(uploadField.files[0]);
  //       }

  //       setB64FileArray([...b64FileSet]);
  //       setFileArray(newFileList);
  //     };
  //   };

  //   const handleFileDelete = async (index) => {
  //     console.log(b64FileArray, fileArray);
  //     const newB64list = b64FileArray.filter((val, i) => i !== index);
  //     const newFileArray = fileArray.filter((val, i) => i !== index);

  //     setB64FileArray(newB64list);
  //     setFileArray(newFileArray);
  //   };
  console.log(module);

  const resetForm = () => {
    setCategory("");
    setMda("");
    setName("");
    setDescription("");
    setPhonenumber("");
    setEmail("");
    setSubCategories("");
    setModule("");
    // set('');
    //     setOrganisingBody('');
    //     setLocation('');
    //     setTotalCost(null);
    //     setPersonalExpense(null);
    //     setUploadedDocuments('');
    //     setFileArray([]);
    //     setB64FileArray([]);

    //     setDtaExpense(null);
    //     setTransportationExpense(null);
    //     setLocalTransportationExpense(null);
    //     setJournalPublicationExpense(null);
    //     setSubscriptionExpense(null);
    //     setContingenciesExpense(null);
  };
  const checkNumber = () => {
    console.log(phoneNumber.length);
    if (phoneNumber.length === 11)
      // console.log()
      return true;
    else return false;
  };
  //   const clearForm = () => {
  //     resetForm();
  //     if (details && details.id) {
  //       closeForm();
  //     }
  //   }
  console.log(category);

  return (
    <div className="notification_modal2">
      <div
        style={{
          display: "flex",
          width: "35%",
          minWidth: "500px",
          margin: "20px auto",
        }}
      >
        <div
          className="bg-white w-100 p-r content"
          style={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "5%",
            alignItems: "center",
            marginRight: "10px",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <p
              style={{ textAlign: "center" }}
              className="bold"
              style={{ fontSize: "20px" }}
            >
              MESSAGE US
            </p>
          </div>
          <div className="col-6  w-100" style={{ marginTop: "20px" }}>
            {/* <SelectInput
      className="w-100 m-b-10"
      value={module}
      onChange={(e) => setModule(e.target.value)}
      label="Modules*"
      options={modules || []}
      /> */}

            <AutoComplete
              label="Select Module"
              options={modules.map((m) => ({
                name: m.name,
                value: m.id,
              }))}
              value={module}
              onChange={(value, name) => {
                console.log(name.name);
                setModule(value);
                setModuleName(name.name);
              }}
              className="w-100 m-b-10"
            />

            <SelectInput
              className="w-100 m-b-10"
              value={category}
              onChange={(e) => {
                setCategory(e.target.value);
              }}
              label="Issue Category*"
              options={categories || []}
            />

            <TextInput
              className="w-100 m-b-10"
              value={name}
              onChange={(e) => setName(e.target.value)}
              label="Name*"
            />

            <AutoComplete
              label="Select MDA"
              options={mdas.map((m) => ({
                name: m.name,
                value: m.id,
              }))}
              value={mda}
              onChange={(value, name) => {
                console.log(name.name);
                setMda(value);
                setMdaName(name.name);
              }}
              className="w-100 m-b-10"
            />

            <TextInput
              className="w-100 m-b-10"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="E-mail*"
            />
            <div>
              <TextInput
                className="w-100 m-b-10"
                value={phoneNumber}
                type="number"
                onChange={(e) => {
                  setPhonenumber(e.target.value);
                  checkNumber();
                }}
                label="Phone Number*"
              />
              {phoneNumber.length > 0 && !checkNumber() && (
                <i style={{ color: "red" }}>
                  please make sure numbers are 11 digit
                </i>
              )}
            </div>
            <TextareaInput
              className="w-100 m-b-10"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              label="Describe your Issue*"
            />

            <div className=" no-margin">
              <button
                type="button"
                className="btn btn-large w-100"
                onClick={() => submitForm("submit")}
                disabled={isSubmitting}
              >
                Submit
                {isSubmitting && (
                  <img src={Loader} className="btn-loading" alt="Loading..." />
                )}
              </button>
            </div>
          </div>
        </div>
        <div
          className="pointer"
          onClick={() => {
            prop.offFunc();
          }}
        >
          <AiOutlineClose
            style={{
              backgroundColor: "red",
              width: "40px",
              height: "40px",
              color: "white",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceManagement;
